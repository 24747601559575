import React, { Component } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, H1, H2, Primarybutton, Row, Subtext } from '../general';

const ContactHero = () => {
    const { t } = useTranslation();
    const url = 'https://getform.io/f/1f172071-86fb-4af9-9ca1-743052263f24';
    console.log('url')
    return (
        <div className='relative'>
            <Col className='relative z-10 container mx-auto items-center'>
                <H1 className="text-white text-center" text={t('contact.title')}/>
                <H2 text={t('contact.subtext')} className="text-white text-center" />
                <div className='mt-10'>
                    <form method="post" action="url">
                        <Col>
                            <input type="hidden" name="bot-field" />
                            <Row>
                                <input type="text" name="fname" id="fname" placeholder={t('contact.fname')} />
                                <input type="text" name="lname" id="lname" placeholder={t('contact.lname')}/>
                            </Row>
                            <input type="email" name="email" id="email" placeholder={t('contact.email')} />
                            <input type="text" name="employees" id="employees" placeholder={t('contact.employees')} />
                            <textarea name="message" id="message" rows="5" placeholder={t('contact.ask')} />
                            <Row className="justify-center">
                                <button type="submit"><Primarybutton text={t('contact.submit')} /></button>
                            </Row>
                        </Col>
                    </form>
                </div>

            </Col>
        </div>
    );

}

export default ContactHero;